/* markdown-styles.css */
.markdown-body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial;
  line-height: 1.6;
  padding: 20px;
  color: #24292e;
  background-color: #ffffff;
  max-width: 900px;
  margin: 0 auto;
}

/* Dark mode support */
@media (prefers-color-scheme: dark) {
  .markdown-body {
    color: #c9d1d9;
    background-color: #0d1117;
  }
}

/* Headers */
.markdown-body h1,
.markdown-body h2,
.markdown-body h3,
.markdown-body h4,
.markdown-body h5,
.markdown-body h6 {
  margin-top: 24px;
  margin-bottom: 16px;
  font-weight: 600;
  line-height: 1.25;
}

/* Code blocks */
.code-block {
  background: #1e1e1e;
  border-radius: 6px;
  margin: 16px 0;
}

.code-header {
  background: #2d2d2d;
  padding: 8px 16px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  color: #e1e1e1;
  font-size: 0.85em;
}

.markdown-body pre {
  margin: 0;
  padding: 16px;
  overflow: auto;
}

/* Tables */
.table-container {
  overflow-x: auto;
  margin: 16px 0;
}

.markdown-body table {
  border-collapse: collapse;
  width: 100%;
}

.markdown-body table th,
.markdown-body table td {
  padding: 6px 13px;
  border: 1px solid #dfe2e5;
}

/* Images */
.image-container {
  text-align: center;
  margin: 16px 0;
}

.image-container img {
  max-width: 100%;
  height: auto;
}

/* Blockquotes */
.markdown-body blockquote {
  margin: 0;
  padding: 0 1em;
  color: #6a737d;
  border-left: 0.25em solid #dfe2e5;
}

/* Lists */
.markdown-body ul,
.markdown-body ol {
  padding-left: 2em;
}

/* Links */
.markdown-body a {
  color: #0366d6;
  text-decoration: none;
}

.markdown-body a:hover {
  text-decoration: underline;
}

/* Task lists */
.markdown-body input[type="checkbox"] {
  margin-right: 0.5em;
}

/* TOC styles */
.toc-only {
  font-size: 0.9em;
}

.toc-only ul {
  list-style: none;
  padding-left: 0;
}

.toc-only li {
  margin: 0.5em 0;
  padding-left: 1em;
}

.toc-only a {
  color: #4b5563;
  text-decoration: none;
  display: block;
  padding: 0.25em 0;
  transition: color 0.2s;
}

.toc-only a:hover {
  color: #2563eb;
}

/* Sticky sidebar */
.sticky {
  position: sticky;
  top: 0;
  height: 100vh;
  overflow-y: auto;
  padding-bottom: 2rem;
  scrollbar-width: thin;
  scrollbar-color: #cbd5e0 #f7fafc;
}

.sticky::-webkit-scrollbar {
  width: 4px;
}

.sticky::-webkit-scrollbar-track {
  background: #f7fafc;
}

.sticky::-webkit-scrollbar-thumb {
  background-color: #cbd5e0;
  border-radius: 2px;
}

/* Dark mode adjustments */
.dark .toc-only a {
  color: #9ca3af;
}

.dark .toc-only a:hover {
  color: #60a5fa;
}

.dark .toc a {
  color: #9ca3af;
}

.dark .toc a:hover {
  color: #60a5fa;
  border-left-color: #60a5fa;
}

.dark .sticky::-webkit-scrollbar-track {
  background: #1a202c;
}

.dark .sticky::-webkit-scrollbar-thumb {
  background-color: #4a5568;
}

/* TOC Styles */
.toc {
  position: relative;
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  scrollbar-width: thin;
}

.toc a {
  display: block;
  padding: 4px 0;
  text-decoration: none;
  transition: all 0.2s;
  color: #4b5563;
}

.toc a:hover {
  color: #3b82f6;
  padding-left: 4px;
}

.toc::-webkit-scrollbar {
  width: 4px;
}

.toc::-webkit-scrollbar-track {
  background: transparent;
}

.toc::-webkit-scrollbar-thumb {
  background-color: #cbd5e0;
  border-radius: 2px;
}

.dark .toc::-webkit-scrollbar-thumb {
  background-color: #4a5568;
}

/* 树形目录样式 */
.toc ul {
  list-style: none;
  margin: 0;
  padding-left: 1rem;
}

.toc li {
  position: relative;
}

.toc li::before {
  content: '';
  position: absolute;
  left: -1rem;
  top: 0.7em;
  width: 0.75rem;
  height: 1px;
  background: #d1d5db;
}

.toc li:last-child::after {
  display: none;
}

.dark .toc li::before {
  background: #4b5563;
}

/* 滚动条样式 */
.toc {
  scrollbar-width: thin;
  scrollbar-color: #cbd5e0 transparent;
}

.toc::-webkit-scrollbar {
  width: 4px;
}

.toc::-webkit-scrollbar-track {
  background: transparent;
}

.toc::-webkit-scrollbar-thumb {
  background-color: #cbd5e0;
  border-radius: 2px;
}

.dark .toc::-webkit-scrollbar-thumb {
  background-color: #4a5568;
} 